export const apiUrl = process.env.VUE_APP_API_URL;
export const apiUrlNoProject = process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME;

export const defaultLanguageId = 1;
export const needLanguage =  false;
export const languages = {
    1: {
        id: 1,
        code: "hu",
        target_link : ""
    }
   
};
export const defaultPage = 'Home';
export const project = {
        title: 'Soproni'
};
// export const senderEmail = 'bacskai.nikoletta@acg.hu'
// export const senderEmail = 'info@heineken.hu'
export const senderEmail = process.env.VUE_APP_CONTACT_EMAIL

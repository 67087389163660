import Vue from 'vue'
import Vuex from 'vuex'
import * as CONFIG from '@/setup/init.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    faqs: [],
    apiCalls: {},
    showCookie: false,
    language_id: CONFIG.defaultLanguageId,
    prodFamiliesClasses: {
      class_pf_lager: 'product-family--lager',
      class_pf_ovatos_duhaj: 'product-family--ovatos-duhaj',
      class_pf_radler_00: 'product-family--radler-00',
      class_pf_radler_1_4: 'product-family--radler-1-4',
    },
    prodFamiliesId: {
      lager: 'lager',
      ovatos_duhaj: 'ovatos_duhaj',
      radler__00: 'radler_00',
      radler_1_4: 'radler_1_4'
    }
  },
  mutations:{

  },
  actions:{
    
  }
})

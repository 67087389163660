import Vue from 'vue'
import VueRouter from 'vue-router'

import Footer from '@/components/layout/Footer.vue'
import Header from '@/components/layout/Header.vue'

Vue.use(VueRouter)

  const routes = [    
    {
      path: '/', 
      name: 'Home',
      components: {
        default: () => import('@/views/Home.vue'),
        header: Header,
        footer: Footer    
      },
    },     
    {
      path: '/termekek', 
      name: 'ProductFamilyAll',
      components: {
        default: () => import('@/views/ProductFamilyAll.vue'),
        header: Header,
        footer: Footer    
      },
    },  
    {
      path: '/termekek/:product_family_slug', 
      name: 'ProductFamily',
      components: {
        default: () => import('@/views/ProductFamily.vue'),
        header: Header,
        footer: Footer    
      },
    },    
    {
      path: '/termekek/:product_family_slug/:product_slug', 
      name: 'ProductDetails',
      components: {
        default: () => import('@/views/ProductDetails.vue'),
        header: Header,
        footer: Footer    
      },
    },     
    {
      path: '/gasztro', 
      name: 'RecipeAll',
      components: {        
        header: Header,
        footer: Footer,  
        default: () => import('@/views/RecipesAll.vue'),  
      },
    }, 
    {
      path: '/gasztro/:recipe_slug', 
      name: 'RecipeDetails',
      components: {        
        header: Header,
        footer: Footer,
        default: () => import('@/views/RecipeDetails.vue'),      
      },
    }, 
    {
      path: '/tortenelem', 
      name: 'History',
      components: {        
        header: Header,
        footer: Footer,
        default: () => import('@/views/History.vue'),      
      },
    }, 
    {
      path: '/jatekok', 
      name: 'Games',
      components: {        
        header: Header,
        footer: Footer,
        default: () => import('@/views/Games.vue'),      
      },
    }, 
    {
      path: '/promociok', 
      name: 'Promotions',
      components: {        
        header: Header,
        footer: Footer,
        default: () => import('@/views/Promotions.vue'),      
      },
    },    
    {
      path: '/kapcsolat', 
      name: 'Contact',
      components: {
        default: () => import('@/views/Contact.vue'),
        header: Header,
        footer: Footer    
      },
    },
    {
      path: '/csapolt',
      name: 'Csapolt',
      components: {
        default: () => import('@/views/Csapolt.vue'),
        header: Header,
        footer: Footer
      },
    },
    {
      path: '/hetkoznapi-szuperkepessegek',
      name: 'Szuperkepessegek',
      components: {
        default: () => import('@/views/Szuperkepessegek.vue'),
        header: Header,
        footer: Footer
      },
    },
    {
      path: '/mertekkel-sopronizz',
      name: 'FogyaszdFeleloseggel',
      components: {
        default: () => import('@/views/FogyaszdFeleloseggel.vue'),
        header: Header,
        footer: Footer
      },
    },
    {
      path: '/sor-kisokos', 
      name: 'BeerSmart',
      components: {
        default: () => import('@/views/BeerSmart.vue'),
        header: Header,
        footer: Footer    
      },
    },   
    {
      path: '/sorverseny', 
      name: 'BeerCompetition',
      components: {
        default: () => import('@/views/BeerCompetition.vue'),
        header: Header,
        footer: Footer    
      },
    },        
    {
      path: '/404',
      name: '404-page',          
      components: {
          default: () => import('@/views/PageNotFound.vue'),
          header: Header
      }      
    },
    {
      // 404 page
      path: "*",
      redirect: '404'         
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {        
      if (savedPosition){
        return savedPosition
      } else {
        var pos = {}
                
        if (to.hash && to.path == from.path){              
          pos.selector = 'recept-szuro'                  
                    
          if (document.getElementById(pos.selector)){            
            return pos                      
          }           

          return { x: 0, y: 0 }     

        } else {
          return { x: 0, y: 0 }
        }      
      }      
      
  }
  
})

export default router

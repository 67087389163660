<template>
    <footer class="footer" :class="[{'footer--black' : needBlackFooter }, {'footer--animated': isFooterAnimStart}]">        
        <b-container fluid>
                <b-row class="footer__content">
                    <b-col xl="4" lg="12" class="footer__content--logo">
                        <img src="@/assets/images/brand/soproni_COLOR_toltsukegyutt_white_text.png" alt="Soproni">
                    </b-col>

                    <b-col xl="2" lg="2" md="3">
                        <ul class="footer__content--vertical-links">                                   
                            <li v-for="menuItem in footerMenu1" :key="menuItem.id">
                                <b-link :href="menuItem.target_link" @click.native="setGTM(menuItem.name)">{{menuItem.name}}</b-link>
                            </li>
                        </ul>
                    </b-col>

                    <b-col xl="6" lg="10"  md="9">
                        <div class="footer-content-wrap">
                            
                                <div class="footer__content--btn-group" >
                                    <div class="btn-wrap">
                                        <router-link :to="{name: 'FogyaszdFeleloseggel'}" class="btn btn--white-square btn-positioning btn-positioning-all" @click.native="setGTM('fogyaszd felelőséggel')" ><span>fogyaszd felelőséggel</span></router-link>
                                        <router-link :to="{path: '/kapcsolat'}" class="btn btn--white-square btn-positioning-all" @click.native="setGTM('kapcsolat')" ><span>kapcsolat</span></router-link>  
                                    </div>                                            
                                    <SocialMenu :theme="'white'" :place="'footer'"/>                                            
                                </div>                                                                                
                                <div>
                                    <ul class="footer__content--horizontal-links">                                        
                                        <li>
                                            <!-- <a :href="base_url + 'content/informacio/Adatvedelmi_Nyilatkozat.pdf?' + TimeStamp" target="_blank" @click="setGTM('Adatvédelmi nyilatkozat')">
                                                Adatkezelési tájékoztató
                                            </a> -->
                                            <a href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato" target="_blank" @click="setGTM('Adatvédelmi nyilatkozat')">
                                                Adatkezelési tájékoztató
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="base_url + 'content/informacio/Cookie_Policy.pdf?' + TimeStamp" target="_blank" @click="setGTM('Cookie tájékoztató')">
                                                Cookie tájékoztató
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="base_url + 'content/informacio/Jogi_nyilatkozat.pdf?' + TimeStamp" target="_blank" @click="setGTM('Jogi nyilatkozat')">
                                                Jogi nyilatkozat
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="javascript:void(0)" @click="$store.state.showCookie= true;setGTM('Cookie beállítás')">
                                                Cookie beállítások módosítása
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <p>{{ currentYear }} HEINEKEN HUNGÁRIA ZRT. MINDEN JOG FENNTARTVA</p>
                        </div>    
                    </b-col>
            </b-row>
              
        </b-container>  
    </footer>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import SocialMenu from '@/components/layout/SocialMenu.vue'


export default {
    mixins: [apiH],
    components:{
        SocialMenu,
        
    },
    data(){
        return{
            footerMenu1:[],
            footerMenu2:[],
            isFooterAnimStart: false
        }
    },
    created(){
        this.getFooterMenu1()
        this.footerAnimStart()
        // this.getFooterMenu2()
    },
    watch:{
        $route (){
            this.isFooterAnimStart = false
            this.footerAnimStart()
        }
    },
    computed:{
        needBlackFooter(){               
            let currentRoute = this.$route.path.split('/')
             
            return (this.$route.params.product_family_slug && this.$route.params.product_family_slug == this.$store.state.prodFamiliesId.ovatos_duhaj) || 
            currentRoute[1] === 'gasztro' || 
            currentRoute[1] === 'sor-kisokos' ||
            currentRoute[1] === 'sorverseny'
        },
        currentYear(){
            const date = new Date()

            return date.getFullYear()
        }
    },
    methods:{
        getFooterMenu1(){
            let _this = this

            _this.get('helper/menu/2',
                {
                    params: {                                                  
                        where: [['where','status', '1'],],
                        order_by:[['sort_order', 'asc']]
                    }
                },
                function(resp){                                        
                    _this.footerMenu1 = resp.data.menu                
                    
                }, function(err){
                    console.log(err);
                    _this.footerMenu1 = []
                }
            )
        },
        footerAnimStart(){
            let _this = this
            setTimeout(() =>{
                _this.isFooterAnimStart = true
            }, 300)
        },
        setGTM(item){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'menu',
                'action': 'lablec',
                'label': item,                
                'menu': 'lablec',
                'clicked_text':item,
                'action_type': 'menu_kattintas'
            })
        }
    }
}
</script>
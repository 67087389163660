import Vue from 'vue'
import App from './App.vue'

import router from './setup/router.js'
import store from './setup/store.js'

//cookie
import CookieBanner from '@/plugins/cookiebanner/cookie-banner.js'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto';

Vue.use(VueCookies)
Vue.use(CookieBanner)

//bootstrap
import {BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//css
import '@/css/main.min.css'
import globalMI from './mixins/globalMI.js';
import axios from 'axios'

Vue.use(VueScrollTo, {
  container: "body",
  duration: 750,
  easing: "ease",
  offset: -110,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


Vue.prototype.$axios = axios
Vue.use(BootstrapVue)
Vue.mixin(globalMI)

Vue.config.productionTip = false

//DIRECTIVES
// Vue.directive('inview', {  
//   inserted(el, binding) {         
//       function isInViewport(item, classname){
//         let rect = item.getBoundingClientRect(); 
//         let windowHeight = window.innerHeight                              
        
//         // console.log(windowHeight, item, rect.top)
//         if ( rect.top >= 0 && rect.top < windowHeight ) {                     
//           item.classList.add(classname)          
//         } 
//       }
        
//       el.classList.add('animateScroll')       
//       isInViewport(el, binding.value)
      
//       setTimeout(() => {                    
//           // isInViewport(el, binding.value)
//           window.scrollTo(0, 1); //hack
//       }, 500); //settimeout hack
      
//       window.addEventListener('scroll', function(){
//         isInViewport(el, binding.value)
//       })
      
//    }   
//  });


Vue.directive('inview', {  
  bind(el){
    el.classList.add('animateScroll')   
  },
  inserted(el, binding) {         

      function isInViewport(item, classname){
        let rect = item.getBoundingClientRect(); 
        let windowHeight = window.innerHeight                                      
        
        // console.log(windowHeight, item, rect.top)
        if ( rect.top >= 0 && rect.top < windowHeight ) {                     
          item.classList.add(classname)          
        } 
      }

      function initAnimation(){        
        if (document.readyState === 'complete') {                    
            // el.classList.add('animateScroll')       
            isInViewport(el, binding.value)
            clearInterval(animPageLoad)
        }  
      }
                    
      var animPageLoad = setInterval(() => {
        initAnimation()
      }, 300);           
      
      window.addEventListener('scroll', function(){
        isInViewport(el, binding.value)
      })
      
   }   
 });


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <router-view name="header"/>
    <transition name="changePage" mode="out-in" >
      <div class="page-outer" ref="pageOuter">
        <router-view/>
      </div>
    </transition>
    <router-view name="footer"/>
    <AgeGateCookie/>
    <CookieBannerComp  class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>            
             
  </div>
</template>

<script>
import AgeGateCookie from '@/components/layout/AgeGateCookie.vue'
import apiH from '@/mixins/apiHandlerMI.js'

export default {
  mixins: [apiH],
  components:{
    AgeGateCookie
  },
  mounted(){
    this.setPageOuterHeight()    
    //this.sendCache();
    // console.log(window.location.pathname.split('/'))
  },
  watch:{
    $route(){
      this.setPageOuterHeight();
      this.sendCache();
      // this.GTtrackGA4({event:"event", category: "Navigation", action: "routechange", label: "empty"});
       window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
      });
    }
  },
  methods:{
    setPageOuterHeight(){

      // console.log(this.$route.path)
      
      if (
        this.$route.path == '/kapcsolat' || 
        this.$route.path == '/jatekok' || 
        this.$route.path == '/promociok' ||
        this.$route.path == '/sorverseny'        
      ){
        this.$refs.pageOuter.classList.add('little-height')
      } else {
        this.$refs.pageOuter.classList.remove('little-height')
      }
    },
    sendCache(){
      //page cache
     
        var _this = this;
        if (window['need_content']){
          setTimeout(function(){
          console.log('save');
          var meta = '';
          var page = document.getElementById('app').outerHTML;
          // console.log(page);
          for (var i = 0; i < document.getElementsByTagName('meta').length; i++)
          {
            meta += document.getElementsByTagName('meta')[i].outerHTML;
            // console.log(meta);
          }
          _this.post('pagecache',{
            path: window.location.pathname,
            variables: window.location.search.substring(1),
            head: meta,
            body: page

          },{},function(resp){
            console.log(resp)
          })
          
        },1000);
        }
        
      }
    
  }
}
</script>
<style >
.custom-control-input:checked ~.custom-control-label::before {
         background: #064D2B !important;
         border-color:#064D2B !important;
    }
</style>


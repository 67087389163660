<template>
    <header class="main-header" :class="[{'opened': menuIsOpen}, {'header--black' : needBlackHeader }, {'scrollDown': scrollDown}]" id="maiHeader">
        <div class="main-header__row">
            <button 
                id="mainMenuBtn"
                class="btn btn--menu" :class="{'opened': menuIsOpen}"
                @click="toggleMenu()"
            >
                <span class="d-none d-md-inline">Menü</span>               
                <div class="hamburger-icon">
                    <div></div>                    
                </div>
            </button>
            <router-link :to="{path: '/'}" class="logo" @click.native="setGTM('logo')">
                <img src="@/assets/images/brand/ovatos-duhaj-logo.svg" alt="Soproni Óvatos Duhaj" class="w-100" v-if="needBlackHeader" />
                <img src="@/assets/images/brand/soproni_COLOR_toltsukegyutt_logo.png" alt="Soproni" class="w-100" v-else />
            </router-link>          
            <div class="d-none d-md-flex">
                <SocialMenu :theme="'white'" :place="'header'" v-if="needBlackHeader"/>                     
                <SocialMenu :theme="'green'" :place="'header'" v-else/>                     
            </div>
        </div>
        <!-- MENU -->
        <div class="main-menu-wrap" id="mainMenu" :class="{'opened': menuIsOpen}" >    
            <nav class="navbar main-menu" id="menuInsideCont" v-if="mainMenu.length > 0">
                <div id="scrollCont">
                    <VuePerfectScrollbar class="scroll-area" >
                        <ul class="navbar-nav main-menu-nav" id="mainMenuNav">
                            <li class="nav-item" v-for="menuItem in mainMenu" :key="menuItem.id" :class="[{'openedSubMenu': menuItem.showSubMenu == true},{'hasChildren': menuItem.children}]">
                                <router-link class="nav-link" :to="menuItem.target_link" @click.native="setGTM(menuItem.name)">
                                    {{ menuItem.name }}                                    
                                </router-link>         
                                <div class="arrow" v-if="menuItem.children" @click="submenuToggle(menuItem)"></div>
                                <ul v-if="menuItem.children" class="subMenu" >
                                    <li class="nav-item" v-for="submenuItem in menuItem.children" :key="submenuItem.id" >
                                        <router-link @click.native="setGTM(submenuItem.name)" class="nav-link" :to="submenuItem.target_link">{{ submenuItem.name }}</router-link>
                                    </li>
                                </ul>
                            </li>                                   
                        </ul>             
                    </VuePerfectScrollbar> 
                </div>
                <ul class="navbar-nav webshop-nav">
                    <li class="webshop-link nav-item">
                        <a href="https://bevasarlas.tesco.hu/groceries/hu-HU/search?query=soproni&department=22218&viewAll=department" class="nav-link" target="_blank" @click="setGTM('webshop')">Webshop</a>
                    </li>   
                </ul>  
            </nav>   
            <div class="main-menu__social-menu--mobile d-md-none">                                
                <SocialMenu :theme="'white'" :place="'header'"/>                     
            </div>            
             <!-- PRODUCTS -->
            <div class="main-menu__products d-none d-md-flex" v-if="menuProducts.length>0">
                <div class="product" v-for="product in menuProducts" :key="product.id">
                    <img :src="src_url + itemSrc(product.card_image, 'w122h260')" :alt="product.name" v-if="product.card_image" />
                    <img :src="src_url + itemSrc(product.main_image, 'w122h260')" :alt="product.name" v-else />
                    <div class="name font-solido">{{product.name}}</div>
                    <a :href="product.shop_link" target="_blank" class="btn btn--green-border btn--shop" @click="setGTMWebshop(product.name)"><span>tovább a webshopba</span></a>
                </div>
            </div>
        </div>       
    </header>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import SocialMenu from '@/components/layout/SocialMenu.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    mixins: [apiH],
    components:{
        SocialMenu,
        VuePerfectScrollbar
    },
    data(){
        return{
            menuIsOpen:false,
            mainMenu: [],
            menuProductsId: [],
            menuProducts: [],
            lastScrollTop:0,
            scrollDown:false
        }
    },
    computed:{
        needBlackHeader(){               
            let currentRoute = this.$route.path.split('/')
             
            return (this.$route.params.product_family_slug && this.$route.params.product_family_slug == this.$store.state.prodFamiliesId.ovatos_duhaj) || 
                currentRoute[1] === 'gasztro' || 
                currentRoute[1] === 'sor-kisokos' ||
                currentRoute[1] === 'sorverseny'
        }
    },
    created(){
        let _this = this

        this.getMainMenu()
        this.getMenuProducts()

        window.addEventListener("scroll", function(){
            _this.scrollDirection()
        }, false);
    },        
    watch:{
        $route(){
            this.menuIsOpen = false
            let body = document.getElementsByTagName('body')[0]        
            body.classList.remove('noScroll') 
            
        }
    },
    methods:{
        scrollDirection(){   
            let _this = this         
                        
            var st = window.pageYOffset || document.documentElement.scrollTop; 
            if (st > this.lastScrollTop){
                // downscroll
                // console.log('scroll down')
                _this.scrollDown = true
            } else {
                // upscroll 
                // console.log('scroll up')
                _this.scrollDown = false
            }
            this.lastScrollTop = st <= 0 ? 0 : st;             
        },
        toggleMenu(){
            let _this = this
            let body = document.getElementsByTagName('body')[0]            

            _this.menuIsOpen = !_this.menuIsOpen

            if (_this.menuIsOpen){
                document.addEventListener('click', _this.isClickOutside, false)                
                body.classList.add('noScroll') 
                
            } else {
                document.removeEventListener('click',_this.isClickOutside, false)                
                body.classList.remove('noScroll')                 
            }
            
        },
        isClickOutside(event){
            // console.log(event.target)
            let _this = this
            let el = document.getElementById('mainMenu')
            let elBtn = document.getElementById('mainMenuBtn')
            let body = document.getElementsByTagName('body')[0] 

            if ( !(el == event.target || el.contains(event.target) || elBtn == event.target || elBtn.contains(event.target))){
                // outside the menu
                _this.menuIsOpen = false
                body.classList.remove('noScroll') 
            } 
        },
        submenuToggle(menuItem){                           
            let currentMenuItem = menuItem                          
        
            if ( currentMenuItem.showSubMenu == true ){
                currentMenuItem.showSubMenu = false                          
                
            } else {
                currentMenuItem.showSubMenu = true                                                  
            }      
            
            setTimeout(() => {
                this.menuHeightCheck()    
            }, 300);
            

        },
        menuHeightCheck(){
            let mInnerScrollCont =  document.getElementById('scrollCont') 
            let mInnerContMaxHeight = mInnerScrollCont.clientHeight            
            let mInnerContHeight = document.getElementById('mainMenuNav').clientHeight                        

            // console.log(mInnerContMaxHeight, mInnerContHeight)

            if ( mInnerContHeight > mInnerContMaxHeight){
                mInnerScrollCont.classList.add('overScroll')
            } else {
                mInnerScrollCont.classList.remove('overScroll')
            }
        },
        getMainMenu(){
            let _this = this

            _this.get('helper/menu/1',
                {
                    params: {                                                                          
                        where: [['where','status', '1'],],
                        order_by:[['sort_order', 'asc']]
                    }
                },
                function(resp){                                        
                    let menu = resp.data.menu   
                    
                    menu.forEach(element => {
                        element.showSubMenu = false
                    });   
                    
                    _this.mainMenu = menu
                    
                }, function(err){
                    console.log(err);
                    _this.mainMenu = []
                }
            )
        },
        getMenuProducts(){
            let _this = this

            _this.get('config/setting/menu-products',
                {                   
                },
                function(resp){                                        
                   _this.menuProductsId = resp.data.value
                   _this.getProducts()
                    
                }, function(err){
                    console.log(err);
                    _this.menuProducts = []
                }
            )
        },
        getProducts(){
            let _this = this

            _this.get('product',
                {
                    params: {                                                                          
                        where: [
                            ['where','status', '1'],
                            ['where_in', 'id', _this.menuProductsId]
                        ],
                        order_by:[['sort_order', 'asc']]
                    }
                },
                function(resp){                                        
                   _this.menuProducts = resp.data.product                                       
                }, function(err){
                    console.log(err);
                    _this.menuProducts = []
                }
            )
        },   
        setGTM(item){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'menu',
                'action': 'fejlec',
                'label': item,                
                'menu': 'fejlec',
                'clicked_text':item,
                'action_type': 'menu_kattintas'
            })
        },
        setGTMWebshop(item){
             this.GTtrackGA4({
                'event' : 'event',
                'category': 'menu',
                'action': 'kosar_gombra_kattintas',
                'button': 'kosar',
                'product': item,
                'action_type':'gomb_kattintas',
                'clicked_text': 'Tovább a webshopba'
            })
        }
    }
}
</script>
<template>
    <ul class="social-menu">             
        <li>
            <a href="https://www.instagram.com/soproni_sor/" target="_blank" class="social-menu__icon" :class="[{'insta--green': theme == 'green'}, {'insta--white': theme == 'white'}]" @click="setGTM('instagram')">               
            </a>
        </li>
        <li>
            <a href="https://www.facebook.com/Soproni" target="_blank" class="social-menu__icon" :class="[{'fb--green': theme == 'green'}, {'fb--white': theme == 'white'}]" @click="setGTM('facebook')">            
            </a>
        </li>
        <li>
            <a href="https://www.youtube.com/user/SoproniTV" target="_blank" class="social-menu__icon"  :class="[{'yt--green': theme == 'green'}, {'yt--white': theme == 'white'}]" @click="setGTM('youtube')">
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props:['theme', 'place'],
    methods:{
        setGTM(item){
            let _this = this            
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'menu',
                'action': _this.place,
                'label': item,                
                'menu': _this.place,
                'clicked_text':item,
                'action_type': 'menu_kattintas'
            })
        }
    }
}
</script>